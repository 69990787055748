import React from 'react';
import styled from 'styled-components';
import BenVideo from '@assets/ben_video.mp4';

const Container = styled.section`
  width: 100%;
  height: 600px;

  @media (max-width: 600px) {
    display: none;
  }
`;

const Video = styled.video`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

export default function HeroVideo({ file }) {
  return (
    <Container>
      <Video loop muted preload="auto" autoPlay>
        <source src={BenVideo} type="video/mp4" />
      </Video>
    </Container>
  );
}
