import React from 'react';
import styled from 'styled-components';
import Thumbnail from '@components/thumbnail';

const Container = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  background-color: #000;

  @media (max-width: 600px) {
    display: block;
    padding-top: 130px;
  }
`;

export default function GridHome({ items, type }) {
  return (
    <Container type={type}>
      {items.map(item => (
        <Thumbnail key={item.slug} {...item} />
      ))}
    </Container>
  );
}
