import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Layout from '@components/layout';
import GridHome from '@components/grid-home';
import HeroVideo from '@components/hero-video';

export default function Work({
  data: { contentfulGallery, contentfulPage },
  location,
}) {
  return (
    <Layout location={location}>
      <Helmet>
        <title>Ben Shirinian</title>
        <link rel="canonical" href="https://lookoutcontent.com" />
      </Helmet>
      <HeroVideo file={contentfulPage.heroVideo.file.url} />
      <GridHome items={contentfulGallery.items} type="work" />
    </Layout>
  );
}

export const pageQuery = graphql`
  fragment ProjectFields on ContentfulProject {
    slug
    client {
      name
    }
    title
    thumbnail {
      file {
        url
      }
    }
  }

  query {
    contentfulPage(title: { eq: "Home" }) {
      heroVideo {
        file {
          url
        }
      }
    }
    contentfulGallery(name: { eq: "Ben" }) {
      items {
        ...ProjectFields
      }
      # column1 {
      #   ...ProjectFields
      # }
      # column2 {
      #   ...ProjectFields
      # }
    }
  }
`;
